// every dependency has to be imported here
// css, scss, image, fonts etc.
import bulma from "./assets/scss/bulma.scss"; // main bulma styles

console.log("Hallo Welt!");
console.log("Tschüss");

import {
    jarallax,
    jarallaxVideo
  } from 'jarallax';
  
  jarallaxVideo();
  
  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.7,
  });